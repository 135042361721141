import React from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import SliderHero from '../../components/home/slider/Slider';
import Partners from '../../components/home/partners/Partners';
import What from '../../components/home/what/What';
import Heroes from "../../components/heroes/Heroes";
import Trustpilot from "../../components/trustpilot/Trustpilot";
import Categories from "../../components/home/categories/Categories";
import Test from "../../components/home/test/Test";
import Banners from "../../components/banners/Banners";
import Diet from "../../components/home/diet/Diet";
import Pictograms from "../../components/home/pictograms/Pictograms";
import Button from "../../components/button/Button";
import Instagram from "../../components/instagram/Instagram";
import Newsletter from "../../components/newsletter/Newsletter";



const FrontPage = (props) => {
  const {
    pageContext: {
      title, seo, uri
    }
  } = props;
  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated

  const lang = locale.substring(0, 2);
  const home = props.pageContext.acfHome.home;
  // home/Slider
  const sliderItems = home.sliderSlider
  // home/Partners
  const partnersItems = home.partnersLogotypes
  const partnersText = home.partnersText
  // home/What
  const whatItems = home.whatList
  const whatHeding = home.whatHeding
  const whatImg = home.whatImg
  // home/Heroes
  const heroItems = home.heroesHeroes
  const heroHeding = home.heroesHeading
  // Trustpilot
  // home/Categories
  const categoriesItems = home.categories;
  const categoriesButton = home.categoriesButton;
  const categoriesHeading = home.categoriesHeading;
  // home/Test
  const testsInfo = home.testsInfo;
  const testsHeading = home.testsHeading;
  const testsDescription = home.testsDescription;
  const testsButton = home.testsButton;
  const testImage = home.testImage
  // Banners
  // home/Diet
  const dietName = home.dietName
  const dietHeading = home.dietHeading
  const dietDescription = home.dietDescription
  const dietPosition = home.dietPosition
  const dietImage = home.dietImage
  // home/Pictograms
  const pictogramsItems = home.pictogramsNew
  // Button
  const allproductsButton = home.allproductsButton
  // Instagram
  // Newsletter


  return (
    <Layout lang={lang} page="home" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"home"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <SliderHero items={sliderItems} />
            <Partners items={partnersItems} text={partnersText} />
            <Banners lang={lang} />
            <Categories items={categoriesItems} button={categoriesButton} heading={categoriesHeading} />
            <What items={whatItems} heading={whatHeding} background={whatImg} />
            <Heroes items={heroItems} heading={heroHeding} />
            <Trustpilot lang={lang} />
            <Test
              info={testsInfo}
              heading={testsHeading}
              description={testsDescription}
              button={testsButton}
              image={testImage}
            />
            <Diet
              heading={dietHeading}
              description={dietDescription}
              image={dietImage}
              name={dietName}
              who={dietPosition}
            />
            <Pictograms items={pictogramsItems} />
            <div className="homeAllProducts__wrapper container">
              <Button url={allproductsButton.url} title={allproductsButton.title} target={allproductsButton.target} />
            </div>
            <Instagram lang={lang} />
            <Newsletter lang={lang} />
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default FrontPage;

