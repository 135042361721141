import React, { useState, useEffect } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { v4 as uuid } from "uuid"
import "./style.scss"

async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  return await response.json()
}

const Banners = ({ lang, items }) => {
  const [banners, updateBanners] = useState([])

  const fetchBannerData = page => {
    ;(async () => {
      switch (lang) {
        case "pl":
          var { data } = await postData(`${process.env.wordPressUrl}/graphql`, {
            query: `query PaginatedBanners {
                        themesOptionsPage {
                          template {
                            banners {
                              image {
                                srcSet
                                sourceUrl
                                sizes
                              }
                              link {
                                target
                                title
                                url
                              }
                            }
                          }
                        }
                    }        
                  `,
          })
          updateBanners(data.themesOptionsPage.template.banners)
          break
        case "en":
          var { data } = await postData(`${process.env.wordPressUrl}/graphql`, {
            query: `query Banners {
                      wpgraphql {
                        themesOptionsPageEn {
                          template {
                            banners {
                              image {
                                srcSet
                                sourceUrl
                                sizes
                              }
                              link {
                                target
                                title
                                url
                              }
                            }
                          }
                        }
                      }
                    }        
                  `,
          })
          console.log(data);
          if(data){
            updateBanners(data.themesOptionsPageEn.template.banners)
          }
          else{
            updateBanners('')
          }
          break
        default:
          break
      }
    })()
  }

  useEffect(() => {
    let isCurrent = true
    fetchBannerData()
    return () => (isCurrent = false)
  }, [])
  return (
    <>
      {banners.length ? (
        items ? (
          <section className="banners">
            <div className="container">
              <div
                className={`banners__wrapper grid grid--${
                  items === 1 ? "1" : "2"
                }`}
              >
                {banners.map((banner, key) =>
                  items > key ? (
                    banner.link ? (
                      <a
                        href={banner.link.url}
                        title={banner.link.title}
                        target={
                          banner.link.target === "_blank"
                            ? banner.link.target
                            : "_self"
                        }
                      >
                        <img key={uuid()} src={banner.image.sourceUrl} />
                      </a>
                    ) : (
                      <img key={uuid()} src={banner.image.sourceUrl} />
                    )
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </section>
        ) : (
          <section className="banners">
            <div className="container">
              <div
                className={`banners__wrapper grid grid--${
                  banners.length === 1 ? "1" : "2"
                }`}
              >
                {banners.map(banner =>
                  banner.link ? (
                    <a
                      href={banner.link.url}
                      title={banner.link.title}
                      target={
                        banner.link.target === "_blank"
                          ? banner.link.target
                          : "_self"
                      }
                    >
                      <LazyLoadImage
                        key={uuid()}
                        alt={banner.image.altText ? banner.image.altText : ""}
                        src={banner.image.sourceUrl} // use normal <img> attributes as props
                        effect="blur"
                        height="80"
                        width="80"
                      />
                    </a>
                  ) : (
                    <LazyLoadImage
                      key={uuid()}
                      alt={banner.image.altText ? banner.image.altText : ""}
                      src={banner.image.sourceUrl} // use normal <img> attributes as props
                      effect="blur"
                      height="80"
                      width="80"
                    />
                  )
                )}
              </div>
            </div>
          </section>
        )
      ) : (
        ""
      )}
    </>
  )
}
export default Banners
