import React, { useState } from "react"
import { useForm } from "react-hook-form"

import "./style.scss"


const NewsletterForm = ({ inputPlaceholder, successText, sendingText, errorEmail, formId, buttonText }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [sendingPass, setsendingPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const onSubmit = (values, e) => {
    if (loading) return;
    const formData = new FormData()
    formData.append("your-email", values.email)
    setLoading(true)
    return fetch(process.env.wordPressUrl + "/wp-json/contact-form-7/v1/contact-forms/" + formId + '/feedback', {
      method: "post",
      body: formData,
    }).then(response => {
      if (response.status === 200) {
        setLoading(false)
        setsendingPass(true)
        e.target.reset()
        setTimeout(() => {
          setsendingPass(false)
        }, 3500);
      }
    })
  }

  return (
    <form className="newsletterForm form" onSubmit={handleSubmit(onSubmit)}>
      <div className="newsletterForm__wrapper">
        <div className="form__input">
          <input
            placeholder={inputPlaceholder}
            type="email"
            {...register("email", {
              required: true,
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: { errorEmail }
              }
            })}
          />
          <span>{errors.email && errorEmail}</span>
        </div>
        <div className="form__button">
          <button type="submit">{buttonText}</button>
        </div>
      </div>
      {loading ? <div className="form__load">{sendingText}</div> : ""}
      <div className="form__ok">
        {sendingPass ? (
          <p className="form__thx">{successText}</p>
        ) : (
          ""
        )}
      </div>
    </form>
  )
}
export default NewsletterForm
